<template>
  <div
    class="width-expand flex gap-m flex-stretch flex-break-column"
    :style="{ 'max-height': height }">
    <div class="conversation-wrapper size-parents">
      <form-button
        class="show-conversations-button margin-l-bottom"
        v-show="!showConversations"
        theme="inverse"
        icon="chat-message"
        text="My conversations"
        @click="showConversations = true"></form-button>
      <conversations
        :class="{ active: showConversations }"
        :conversation-uuid="conversationUuid"
        class="conversations size-parents"
        style="flex: 0 0 300px"
        @conversations:conversation-selected="selectConversation"></conversations>
    </div>

    <div
      class="separator"
      style="
        min-height: 100%;
        width: 1px;
        flex: 0 0 1px;
        background-color: var(--c-gray);
      "></div>
    <chat
      :height="height"
      class="width-expand"
      style="max-height: calc(100lvh - 250px)"
      :conversation-uuid="conversationUuid"></chat>
  </div>
</template>

<script>
import Chat from "@/client/components/chat/Chat.vue";
import Conversations from "@/client/components/chat/Conversations.vue";

export default {
  components: {
    Chat,
    Conversations,
  },
  props: {
    height: {
      type: String,
      default: "50vh",
    },
  },
  data: function () {
    return {
      conversationUuid: false,
      showConversations: false,
    };
  },
  methods: {
    selectConversation(data) {
      if (!data?.conversation?.uuid) {
        return null;
      }

      this.conversationUuid = data.conversation.uuid;
      this.showConversations = false;
    },
  },
};
</script>

<style scoped lang="scss">
.show-conversations-button {
  display: none;
  @media (max-width: 1024px) {
    display: inline-block;
  }
}
.conversations {
  @media (max-width: 1024px) {
    &:not(.active) {
      display: none;
    }
  }
}
</style>
